const servers = [
  {
    "name": "CraftLime",
    "ip": "mc.craftlime.net",
    "logo": "swLogo.png",
    "description": "Founded in 2022, CraftLime has quickly risen to become the #1 Towny server, offering a thriving and dynamic community for players who love building, trading, and creating towns.",
    "social": {
      "discord": "https://discord.gg/lime",
      "instagram": "https://instagram.com/craftlimenw"
    },
    "logoBgColor": "#FFD700",
    "buttonColor": "#FFD700",
    "textColor": "#000000",
    "webSite": "https://craftlime.net"
  },
  {
    "name": "LuneCraft",
    "ip": "mc.lunecraft.net",
    "logo": "lune.svg",
    "description": "Acquired in 2024, LuneCraft offers a genuine and immersive Towny gameplay that appeals to both seasoned players and newcomers.",
    "social": {
      "discord": "https://discord.gg/lunecraft",
      "instagram": "https://instagram.com/lunecraftmc"
    },
    "logoBgColor": "#060A15",
    "buttonColor": "#060A15",
    "textColor": "#FFFFFF",
    "webSite": "https://lunecraft.net"
  },
  {
    "name": "Vanilya",
    "ip": "mc.vanilya.xyz",
    "logo": "vanilya.png",
    "description": "Acquired in 2024, Vanilya offers a relaxed blend of survival and towny gameplay, perfect for those looking to unwind and explore at their own pace. Whether you're building towns, enjoying the thrill of survival mode, or challenging yourself on our Skyblock server, Vanilya provides a laid-back atmosphere where creativity and fun come first.",
    "social": {
      "discord": "https://discord.gg/vanilyaxyz",
      "instagram": "https://instagram.com/vanilyaxyz"
    },
    "logoBgColor": "#40B0C0",
    "buttonColor": "#40B0C0",
    "textColor": "#FFFFFF",
    "webSite": "https://vanilya.xyz"
  },
  {
    "name": "LegendCraftTR",
    "ip": "play.legendcrafttr.com",
    "logo": "lgtr.png",
    "description": "Acquired in 2024, LegendCraftTR has been a cornerstone of the Turkish Minecraft community, bringing players together for years. Our server is dedicated to providing a rich and immersive gameplay experience that caters to all types of players, from newcomers to seasoned veterans.",
    "social": {
      "discord": "https://discord.gg/lgtr",
      "instagram": "#"
    },
    "logoBgColor": "#B73939",
    "buttonColor": "#B73939",
    "textColor": "#FFFFFF",
    "webSite": "https://legendcrafttr.net"
  }
];

export default servers;
